<template lang="pug">
  .foot(v-if="!$store.state.pcshow" to="/")
    p Copyright © 2021中科医创科技有限公司
    p(@click="herfs" style="cursor: pointer") 备案号:京ICP备20023527号
    p(@click="herfs2" style="cursor: pointer;display: flex;align-items: center;justify-content: center;")
      img(src="../assets/img/ba.png" style="float:left;")
      span 京公网安备：
      span 11010502050567号
    p 互联网药品信息服务资格证书编号:(京)- 经营性-2020-0044
    p 中华人民共和国增值电信业务经营许可证编号:京B2-20212233
    p 网络文化经营许可证编号:京网文(2021)2066-549号
  .footer(v-else)
    //- .flix
    //-   .li
    //-     img(src="../assets/img/b4.png")
    //-     span XXX省XXX市XXX路XXX号
    //-   .li
    //-     img(src="../assets/img/b5.png")
    //-     span 000-000000
    //-   .li
    //-     img(src="../assets/img/b6.png")
    //-     span 000-000000
    p Copyright © 2021中科医创科技有限公司
    p(@click="herfs" style="cursor: pointer") 
      span 备案号：
      span.alick 京ICP备20023527号
    div(style="width:100%;margin:0 auto;cursor: pointer;height: 30px;line-height: 30px;" @click="herfs2")
      span(style="display:inline-block;text-decoration:none;height:20px;line-height:20px;")
      p(style="float:left;height:30px;line-height:30px;margin: 0 auto; color:#939393;width:100%;display: flex;align-items: center;justify-content: center;")
        img(src="../assets/img/ba.png" style="float:left;")
        span.alick2 京公网安备：
        span.alick 11010502050567号
    p 互联网药品信息服务资格证书编号：（京）- 经营性-2020-0044
    p 中华人民共和国增值电信业务经营许可证编号：京B2-20212233
    p 网络文化经营许可证编号：京网文（2021）2066-549号
</template>

<script>
export default{
  name: 'Footers',
  data() {
    return {
      
    }
  },
  methods:{
    herfs(){
      window.open('https://beian.miit.gov.cn')
    },
    herfs2(){
      window.open('http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010502050567','_blank')
    }
  }
}
</script>

<style scoped lang="stylus">
  @media screen and (max-width: 960px)
    .foot
      width: 7.5rem;
      // height: 3.56rem;
      background: #292D38;
      padding-top: 0.55rem;
      padding-bottom: 0.55rem;
      p
        font-size: 0.24rem;
        font-family: Source Han Sans CN;
        font-weight: 500;
        color: #FFFEFE;
        // line-height: 0.5rem;
        width: 100%;
        text-align: center
        margin-bottom: 0.3rem
      p:last-child
        margin-bottom:0 !important
    
  @media screen and (min-width: 960px)
    .alick2
      color: #a6a7ab !important;
    .alick
      color: #a6a7ab !important;
      &:hover
        color: #008cff !important;
    .footer
      padding 80px 0 40px
      background #212832
      color #A6A7AB
      .flix
        width 700px 
        margin 0 auto
        display flex
        justify-content space-between
        padding-top 80px
        .li
          display flex
          align-items center
          span
            margin-left 18px
      p
        text-align center
        height 30px
        line-height 30px
        font-size 15px
</style>