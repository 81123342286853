import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import "@/assets/css/base.css"
import 'animate.css'
import {WOW} from 'wowjs';

new WOW({live: false}).init();
import VueLazyLoad from 'vue-lazyload'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'

import './assets/js/rem'

// 配置项
Vue.use(VueLazyLoad, {
  preLoad: 1.3,
  error: 'dist/error.png',
  loading: 'dist/loading.gif',
  attempt: 1
})
router.beforeEach((to, from, next) => {
  NProgress.start()
  next()
})

router.afterEach(() => {
  NProgress.done()
})

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
