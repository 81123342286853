<template lang="pug">
  .headmain(v-if="!$store.state.pcshow")
    .headtop.clearfix
      img(src="../assets/img/img5.png" class="logo")
      img(src="../assets/img/h51.png" class="menu" @click="navshow" v-if="!shownav")
      img(src="../assets/img/close.png" class="menu2" @click="navshow" v-else)
    .headtop2
    .ul(v-if="shownav")
      .li(v-for="(item, index) in list1" :key="index") 
        div(v-if="!item.children") 
          router-link(:to="item.push")
            span(:class="item.actived ? 'li1ACtive' : ''")  {{ item.name}}
        div(v-if="item.children") 
          span(:class="item.actived==true ? 'li1ACtive' : ''"  @click="show")  {{ item.name }}
          img(src="../assets/img/showlist1.png" v-if="!showlist" class="showlistimg")
          img(src="../assets/img/showlist2.png" v-else class="showlistimg")
          ul.child(v-if="showlist")
            li.liItems(v-for="(ite, inde) in item.children" :key="inde") 
              router-link(:to="ite.push")
                span(:class="ite.actived==true ? 'li1ACtive' : ''").childs(@click="chooseCurrent(index)") {{ ite.name }}
  .header(v-else)
    div.headerCon
      img(src="../assets/img/img5.png")
      ul.ul
        li.li(v-for="(item, index) in list" :key="index") 
          div(v-if="!item.children") 
            router-link(:to="item.push")
              span(:class="item.actived ? 'li1ACtive' : 'li'")    {{ item.name}}
          div(v-if="item.children" ) 
            span(:class="item.actived==true ? 'li1ACtive' : 'li'")  {{ item.name }}
            ul.child(v-show="item.children")
              li.liItems(v-for="(ite, inde) in item.children" :key="inde") 
                router-link(:to="ite.push")
                  span.childs(@click="chooseCurrent(index)") {{ ite.name }}
        //- li.phone 400-000-0000
</template>

<script>
export default {
  name: "Headers",
  data() {
    return {
      showlist: false,
      shownav: false,
      list: [
        {
          actived: true,
          name: "首页",
          push: "/",
        },
        {
          actived: false,
          name: "关于公司",
          push: "/about",
        },
        {
          actived: false,
          name: "业务介绍",
          push: "/BusinessIntroduction",
        },
        {
          actived: false,
          name: "战略合作",
          push: "/strategicJoin",
        },
        {
          actived: false,
          name: "产品中心",
          push: "/ProductCenter",
          children: [
            {
              actived: false,
              name: "ThothAPP",
              push: "/thothAPP",
            },
            // {
            //   actived:false,
            //   name: "亿联工坊APP",
            //   push: '/ProductCenter'
            // },
            {
              actived: false,
              name: "彼仙草APP",
              push: "/bxcApp",
            },
            {
              actived: false,
              name: "科创云医App",
              push: "/kcyyApp",
            },
          ],
        },
        // {
        //   actived:false,
        //   name: "企业资讯",
        //   push: '/corporateNews'
        // },
        {
          actived: false,
          name: "联系我们",
          push: "/ContactUs",
        },
      ],
      list1: [
        {
          actived: true,
          name: "首页",
          push: "/",
        },
        {
          actived: false,
          name: "关于公司",
          push: "/about",
        },
        {
          actived: false,
          name: "业务介绍",
          push: "/BusinessIntroduction",
        },
        {
          actived: false,
          name: "战略合作",
          push: "/strategicJoin",
        },
        {
          actived: false,
          name: "产品中心",
          push: "/ProductCenter",
          children: [
            {
              actived: false,
              name: "ThothAPP",
              push: "/thothAPP",
            },
            // {
            //   actived:false,
            //   name: "亿联工坊APP",
            //   push: '/ProductCenter'
            // },
            {
              actived: false,
              name: "彼仙草APP",
              push: "/bxcApp",
            },
            {
              actived: false,
              name: "食品药品",
              push: "/goods",
            },
          ],
        },
        // {
        //   actived:false,
        //   name: "企业资讯",
        //   push: '/corporateNews'
        // },
        {
          actived: false,
          name: "联系我们",
          push: "/ContactUs",
        },
      ],
    };
  },

  methods: {
    navshow() {
      this.shownav = !this.shownav;
    },
    show() {
      this.showlist = !this.showlist;
      this.list[3].actived = !this.list[3].actived;
    },
    chooseCurrent(index) {
      if (!this.$store.state.pcshow) {
        let list = this.list1;
        for (let i = 0; i < list.length; i++) {
          if (index == i) {
            list[i].actived = true;
          } else {
            list[i].actived = false;
          }
        }
        this.list1 = list;
      } else {
        let list = this.list;
        for (let i = 0; i < list.length; i++) {
          if (index == i) {
            list[i].actived = true;
          } else {
            list[i].actived = false;
          }
        }
        this.list = list;
      }
    },
  },
  watch: {
    $route(to, from) {
      this.shownav = false;
      if (!this.$store.state.pcshow) {
        let list = this.list1;
        for (let i = 0; i < list.length; i++) {
          if (list[i].push == to.path) {
            list[i].actived = true;
          } else {
            list[i].actived = false;
          }
        }
        for (var i = 0; i < list[3].children.length; i++) {
          list[3].children[i].actived = false;
          this.showlist = false;
        }
        if (to.name == "ThothApp") {
          list[3].actived = true;
          list[3].children[0].actived = true;
          this.showlist = true;
        }
        if (to.name == "ProductCenter") {
          list[3].actived = true;
          list[3].children[1].actived = true;
          this.showlist = true;
        }
        if (to.name == "BxcApp") {
          list[3].actived = true;
          list[3].children[2].actived = true;
          this.showlist = true;
        }
        if (to.name == "goods") {
          list[3].actived = true;
          list[3].children[3].actived = true;
          this.showlist = true;
        }
        this.list1 = list;
      } else {
        let list = this.list;
        for (let i = 0; i < list.length; i++) {
          if (list[i].push == to.path) {
            list[i].actived = true;
          } else {
            list[i].actived = false;
          }
        }
        for (var i = 0; i < list[3].children.length; i++) {
          list[3].children[i].actived = false;
          this.showlist = false;
        }
        if (to.name == "ThothApp") {
          list[3].actived = true;
          list[3].children[0].actived = true;
          this.showlist = true;
        }
        if (to.name == "ProductCenter") {
          list[3].actived = true;
          list[3].children[1].actived = true;
          this.showlist = true;
        }
        if (to.name == "BxcApp") {
          list[3].actived = true;
          list[3].children[2].actived = true;
          this.showlist = true;
        }
        if (to.name == "goods") {
          list[4].actived = true;
          list[4].children[2].actived = true;
          this.showlist = true;
        }
        this.list = list;
      }
    },
  },
};
</script>

<style scoped lang="stylus">
@media screen and (max-width: 960px)
  .headmain
    position: relative
    .headtop
      width: 7.5rem;
      position: fixed
      left: 0
      top: 0
      z-index: 100000;
      background: #fff;
      .logo
        width: 2.07rem;
        height: 0.8rem;
        display: block;
        float: left;
        padding-top: 0.16rem;
        padding-left: 0.35rem;
      .menu
        display: block;
        float: right;
        width: 0.51rem;
        height: 0.4rem;
        padding-top: 0.3rem;
        padding-right: 0.34rem;
        padding-bottom: 0.15rem;
      .menu2
        display: block;
        float: right;
        width: 0.51rem;
        height: 0.4rem;
        padding-top: 0.3rem;
        padding-right: 0.34rem;
        padding-bottom: 0.15rem;
    .headtop2
      height: 1rem;
    >.ul
      width: 100%;
      position: fixed
      top: 1rem
      bottom:0 ;
      background: #fff;
      -webkit-animation:mymove 1s;
      z-index: 1000000000000;
      .li
        width: calc( 100% - 0.35rem );
        margin-left: 0.35rem;
        border-top: 0.01rem solid #eaeaea
        font-size: 0.3rem;
        font-family: Source Han Sans CN;
        font-weight: 500;
        color: #333333;
        line-height: 1.02rem;
        background: #fff
        position: relative
        a
          display: block
          width: 100%
      .li:nth-child(1)
        margin-left: 0;
        padding-left: 0.35rem
      .li:nth-child(4)
        border-bottom: 0.01rem solid #eaeaea
      .li:nth-child(5)
        width: 100%;
        margin-left: 0;
        border: 0
        span
          display: block
          padding-left: 0.35rem
          width:100%
        .child
          width: 100%
          background: #eaeaea
          li
            width: 100%;
            height: 1.02rem;
            border-top: 0.01rem solid #eaeaea
            font-size: 0.3rem;
            font-family: Source Han Sans CN;
            font-weight: 500;
            color: #333333;
            line-height: 1.02rem;
        .showlistimg
          position: absolute
          right 0.34rem
          top 0.38rem
          width: 0.27rem
          height: 0.27rem

.li1ACtive
  color: #02B2B5 !important
@keyframes mymove
{
  // from {left : 7.5rem;}
  // to {left: 0;}
  // from {top : -7.5rem;}
  // to {top: 1rem;}
  from {opacity: 0;}
  to {opacity: 1;}
}
@media screen and (min-width: 960px)
  .header
    width 100%
    height 90px
    background-color #FFFFFF
    z-index 9999
    position fixed
    top 0px
    left 0px
    .headerCon
      width 1280px
      height 90px
      margin 0 auto
      display flex
      justify-content space-between
      align-items center
      img
        width 157px
        height 62px
      .ul
        display flex
        height 98px
        .childs:hover
            color #FFFFFF
        .li1ACtive
            height 94px
            box-sizing border-box
            border-bottom 4px solid #02B2B5
            color #02B2B5
            .child
              display block
        .phone
          width 124px
          height 32px
          margin-top 32px
          margin-left 25px
          line-height 32px
          text-align center
          background #02B2B5
          font-size 16px
          font-family Source Han Sans CN
          font-weight 400
          color #FFFFFF
        .li
          width 100px
          height 94px
          box-sizing border-box
          line-height 98px
          text-align center
          cursor pointer
          position relative
          color #666666
          span
            display block
          &:hover
            box-sizing border-box
            border-bottom 4px solid #02B2B5
            color #02B2B5
            .child
              display block
          .child
            position absolute
            top 95px
            z-index 11
            display none
            width 160px
            color #666666
            li
              width 160px
              height 48px
              margin-left -30px
              line-height 48px
              background #fff
              span
                display block
              &:hover
                width 160px
                height 48px
                color #FFFFFF
                background #02B2B5
</style>
