import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const getSystemManageComponent = (path) => import("@/views/" + path);

export const constroutes = [
  {
    path: "/",
    name: "index", //登录页
    //component: () => getSystemManageComponent("index")
    component: (resolve) => require(["@/views/index"], resolve),
  },
  {
    path: "/about",
    name: "About", //关于公司
    // component: () => getSystemManageComponent("about")
    component: (resolve) => require(["@/views/about"], resolve),
  },
  {
    path: "/businessIntroduction",
    name: "BusinessIntroduction", //业务介绍
    //component: () => getSystemManageComponent("businessIntroduction")
    component: (resolve) => require(["@/views/businessIntroduction"], resolve),
  },
  {
    path: "/newsDetails",
    name: "newsDetails", //资讯详情
    //component: () => getSystemManageComponent("newsDetails")
    component: (resolve) => require(["@/views/newsDetails"], resolve),
  },

  {
    path: "/thothApp",
    name: "ThothApp", //thothApp
    //component: () => getSystemManageComponent("thothApp")
    component: (resolve) => require(["@/views/thothApp"], resolve),
  },

  {
    path: "/productCenter",
    name: "ProductCenter", //产品中心
    //component: () => getSystemManageComponent("productCenter")
    component: (resolve) => require(["@/views/productCenter"], resolve),
  },
  {
    path: "/bxcApp",
    name: "BxcApp", //产品中心
    component: (resolve) => require(["@/views/bxcApp"], resolve),
  },
  {
    path: "/kcyyApp",
    name: "KcyyApp", //产品中心
    component: (resolve) => require(["@/views/kcyyApp"], resolve),
  },
  {
    path: "/goods",
    name: "goods", //产品中心
    component: (resolve) => require(["@/views/goods"], resolve),
  },
  {
    path: "/corporateNews",
    name: "CorporateNews", //企业资讯
    //component: () => getSystemManageComponent("corporateNews")
    component: (resolve) => require(["@/views/corporateNews"], resolve),
  },
  {
    path: "/contactUs",
    name: "ContactUs", //联系我们
    //component: () => getSystemManageComponent("contactUs")
    component: (resolve) => require(["@/views/contactUs"], resolve),
  },
  {
    path: "/strategicJoin",
    name: "StrategicJoin", //战略合作
    component: (resolve) => require(["@/views/strategicJoin"], resolve),
  },
];
const createRouter = () =>
  new VueRouter({
    mode: "history", // require service support
    scrollBehavior: () => ({ y: 0 }),
    routes: constroutes,
  });

const router = createRouter();

export default router;
