<template lang="pug">
  #app
    headers
    router-view
    footers
</template>
<script>
import headers from '@/layout/headers'
import footers from '@/layout/footers'

export default {
  name: 'App',
  components: {
    headers,
    footers
  }
 
}
</script>

<style lang="stylus">
  @media screen and (max-width: 960px)
    #app
      background #fff
      font-family SourceHanSansCN-Regular
      width 7.5rem
      overflow hidden
      img[lazy="loading"]
        display block
        width 50px !important
        height 50px !important
        margin 0 auto
      a
        -webkit-tap-highlight-color: transparent;
        -webkit-touch-callout: none;
        -webkit-user-select: none;
      .clearfix:after
        visibility:hidden;
        display:block;
        font-size:0;
        content: " ";
        clear:both;height:0;
      input,textarea
        -webkit-tap-highlight-color: rgba(255, 255, 255, 0); 
        -webkit-user-select: none;
        -moz-user-focus: none;
        -moz-user-select: none;
        -webkit-appearance:none;
        outline: none;
        border: none;
  @media screen and (min-width: 960px)
    #app
      background #fff
      font-family SourceHanSansCN-Regular
      img[lazy="loading"]
        display block
        width 50px !important
        height 50px !important
        margin 0 auto
    
</style>

